/** Main site script should go in this file. */

jQuery(document).ready(function() {
	// Smooth scrolling
	jQuery('a[href^="#"]').click(function(e) {
		e.preventDefault();
		var target = this.hash;
		if (target == '') return false;
		jQuery('html, body').stop().animate({
			'scrollTop': jQuery(target).offset().top
		}, 1200, 'swing');
	});
	
	// Set up SmartMenus
	setupNav();
	// Set up breakpoint detection
	initViewBreakpoints();
	initBreakpoint();
	// Set up dropdown handler
	initOpenClose();
});

// Initialize main navigation menu and menu behaviors
function setupNav() {

	jQuery('#main-menu__items').smartmenus({
		subIndicatorsPos: 'append',
		subMenusMinWidth: '100%',
		bottomToTopSubMenus: false
	});

	// Menu behaviors 
	var $mainMenuState = jQuery('#main-menu__state');
	if ($mainMenuState.length) {
		$mainMenuState.change(function(e) {
			var $menu = jQuery('#main-menu__items');
			if (this.checked) {
				// $menu.hide().slideDown(250, function() { $menu.css('display', ''); });
				$menu.show().animate({right: "15px"}, 250, function() { $menu.css('display', ''); });
			} else {
				// $menu.show().slideUp(250, function() { $menu.css('display', ''); });
				$menu.show().animate({right: "-230px"}, 250, function() { $menu.css('display', ''); });
			}
		});
		// Hide mobile menu when window is unloaded 
		// (prevents the menu from being shown on refresh)
		jQuery(window).bind('beforeunload unload', function() {
			if ($mainMenuState[0].checked) {
				$mainMenuState[0].click();
			}
		});
	}
}

function initViewBreakpoints() {
	function moveBlockTo(block, target) {
		var $block = jQuery(block);

		if(!$block.length || !jQuery(target).length ) return;
		if('#' + $block.parent().attr('id') !== target) {
			$block.appendTo(jQuery(target));
		}
	}

	function prependBlockTo(block, target) {
		var $block = jQuery(block);

		if(!$block.length || !jQuery(target).length ) return;
		if('#' + $block.parent().attr('id') !== target) {
			$block.prependTo(jQuery(target));
		}
	}

	jQuery(window).on('bp:xs', function(e) {
		moveBlockTo('.hp-info-links', '.hp-info-row');
		moveBlockTo('.hp-info-text', '.hp-info-row');
		prependBlockTo('.homepage__info__title', '.homepage__info__side');
	});
	jQuery(window).on('bp:sm bp:md bp:lg bp:xl', function(e) {
		moveBlockTo('.hp-info-text', '.hp-info-row');
		moveBlockTo('.hp-info-links', '.hp-info-row');
		prependBlockTo('#slide-1.homepage__info__title', '#slide-1.homepage__info__body');
		prependBlockTo('#slide-2.homepage__info__title', '#slide-2.homepage__info__body');
		prependBlockTo('#slide-3.homepage__info__title', '#slide-3.homepage__info__body');
		prependBlockTo('#slide-4.homepage__info__title', '#slide-4.homepage__info__body');
	});
	jQuery(window).on('bp:xs bp:sm', function(e) {
		moveBlockTo('.request-info', '.sidebar-desktop');
		prependBlockTo('.sidenav__outer', '.sidebar-col');
		moveBlockTo('.classes .tinted-block', '.sidebar-desktop');
		moveBlockTo('.classes .media-contact', '.sidebar-desktop');
	});
	jQuery(window).on('bp:md bp:lg bp:xl', function(e) {
		prependBlockTo('.sidenav__outer', '.sidebar-desktop');
		moveBlockTo('.classes .tinted-block', '.sidebar-desktop');
		moveBlockTo('.media-contact', '.sidebar-desktop');
		moveBlockTo('.request-info', '.sidebar-desktop');
	})
}

// open-close init
// Allows for simple, uniform setup of animated dropdown menus
function initOpenClose() {
	ResponsiveHelper.addRange({
		'..991': {
			on: function() {
				jQuery('.sidenav__wrap').openClose({
					activeClass: 'sidenav--active',
					opener: '.sidenav__title',
					slider: '.sidenav',
					animSpeed: 400,
					effect: 'slide'
				});			
			},
			off: function() {
				jQuery('.sidenav__wrap').openClose('destroy');
			}
		}
	});

	// ResponsiveHelper.addRange({
	// 	'..767': {
	// 		on: function() {
	// 			jQuery('.about').openClose({
	// 				activeClass: 'active',
	// 				opener: '.slide__opener',
	// 				slider: '.slider',
	// 				animSpeed: 400,
	// 				effect: 'slide'
	// 			});
	// 		},
	// 		off: function() {
	// 			jQuery('.about').openClose('destroy');
	// 		}
	// 	}
	// });

	jQuery('.header__translate').openClose({
		activeClass: 'header__translate--active',
		opener: '.header__translate__title',
		slider: '.header__translate__list',
		animSpeed: 400,
		effect: 'none'
	});
	jQuery('.header__login').openClose({
		activeClass: 'header__login--active',
		opener: '.header__login__title',
		slider: '.header__login__list',
		animSpeed: 400,
		effect: 'none'
	});
	jQuery('.header .search').openClose({
		activeClass: 'search--active',
		opener: '.header__search',
		slider: '.header__search--bar',
		animSpeed: 400,
		effect: 'slide'
	});
	jQuery('.adv-search').openClose({
		activeClass: 'adv-search--active',
		opener: '.adv-search__title',
		slider: '.adv-search__content',
		animSpeed: 400,
		effect: 'slide'
	});
	jQuery('.class__entry__adv').openClose({
		activeClass: 'class__entry__adv--active',
		opener: '.adv-class-title',
		slider: '.class__entry__info--adv',
		animSpeed: 400,
		effect: 'slide'
	});
	jQuery('.news__cat--news').openClose({
		activeClass: 'news__cat--active',
		opener: '.news__cat--dd__title',
		slider: '.news__cat--body',
		animSpeed: 400,
		effect: 'none'
	});
	jQuery('.news__cat--class-1').openClose({
		activeClass: 'news__cat--active',
		opener: '.news__cat--dd__title',
		slider: '.news__cat--body',
		animSpeed: 400,
		effect: 'none'
	});
	jQuery('.news__cat--class-2').openClose({
		activeClass: 'news__cat--active',
		opener: '.news__cat--dd__title',
		slider: '.news__cat--body',
		animSpeed: 400,
		effect: 'none'
	});
}

// Enable entry-key clickability for dd menus to maintain accessibility
jQuery(document).ready(function($) {
	$('.header__login__title, .header__search, .sidenav__title, .homepage__info__side__entry, .header__translate__title, .main-menu__btn, .main-menu__btn--open, .adv-search__title, .adv-class-title, .news__cat--dd__title').keypress(function (e) {
		var key = e.which;
		if(key == 13)
		{
			$(this).click();
			return false;  
		}
	});
});

function initBreakpoint() {
	jQuery(window).breakpointEvents();
}

/*
 * Responsive Layout helper
 */
window.ResponsiveHelper = (function($){
	// init variables
	var handlers = [],
		prevWinWidth,
		win = $(window),
		nativeMatchMedia = false;

	// detect match media support
	if(window.matchMedia) {
		if(window.Window && window.matchMedia === Window.prototype.matchMedia) {
			nativeMatchMedia = true;
		} else if(window.matchMedia.toString().indexOf('native') > -1) {
			nativeMatchMedia = true;
		}
	}

	// prepare resize handler
	function resizeHandler() {
		var winWidth = win.width();
		if(winWidth !== prevWinWidth) {
			prevWinWidth = winWidth;

			// loop through range groups
			$.each(handlers, function(index, rangeObject){
				// disable current active area if needed
				$.each(rangeObject.data, function(property, item) {
					if(item.currentActive && !matchRange(item.range[0], item.range[1])) {
						item.currentActive = false;
						if(typeof item.disableCallback === 'function') {
							item.disableCallback();
						}
					}
				});

				// enable areas that match current width
				$.each(rangeObject.data, function(property, item) {
					if(!item.currentActive && matchRange(item.range[0], item.range[1])) {
						// make callback
						item.currentActive = true;
						if(typeof item.enableCallback === 'function') {
							item.enableCallback();
						}
					}
				});
			});
		}
	}
	win.bind('load resize orientationchange', resizeHandler);

	// test range
	function matchRange(r1, r2) {
		var mediaQueryString = '';
		if(r1 > 0) {
			mediaQueryString += '(min-width: ' + r1 + 'px)';
		}
		if(r2 < Infinity) {
			mediaQueryString += (mediaQueryString ? ' and ' : '') + '(max-width: ' + r2 + 'px)';
		}
		return matchQuery(mediaQueryString, r1, r2);
	}

	// media query function
	function matchQuery(query, r1, r2) {
		if(window.matchMedia && nativeMatchMedia) {
			return matchMedia(query).matches;
		} else if(window.styleMedia) {
			return styleMedia.matchMedium(query);
		} else if(window.media) {
			return media.matchMedium(query);
		} else {
			return prevWinWidth >= r1 && prevWinWidth <= r2;
		}
	}

	// range parser
	function parseRange(rangeStr) {
		var rangeData = rangeStr.split('..');
		var x1 = parseInt(rangeData[0], 10) || -Infinity;
		var x2 = parseInt(rangeData[1], 10) || Infinity;
		return [x1, x2].sort(function(a, b){
			return a - b;
		});
	}

	// export public functions
	return {
		addRange: function(ranges) {
			// parse data and add items to collection
			var result = {data:{}};
			$.each(ranges, function(property, data){
				result.data[property] = {
					range: parseRange(property),
					enableCallback: data.on,
					disableCallback: data.off
				};
			});
			handlers.push(result);

			// call resizeHandler to recalculate all events
			prevWinWidth = null;
			resizeHandler();
		}
	};
}(jQuery));

/*
 * jQuery Open/Close plugin
 */
;(function($) {
	function OpenClose(options) {
		this.options = $.extend({
			addClassBeforeAnimation: true,
			hideOnClickOutside: false,
			activeClass: 'active',
			opener: '.opener',
			slider: '.slide',
			animSpeed: 400,
			effect: 'fade',
			event: 'click'
		}, options);
		this.init();
	}
	OpenClose.prototype = {
		init: function() {
			if (this.options.holder) {
				this.findElements();
				this.attachEvents();
				this.makeCallback('onInit', this);
			}
		},
		findElements: function() {
			this.holder = $(this.options.holder);
			this.opener = this.holder.find(this.options.opener);
			this.slider = this.holder.find(this.options.slider);
		},
		attachEvents: function() {
			// add handler
			var self = this;
			this.eventHandler = function(e) {
				e.preventDefault();
				if (self.slider.hasClass(slideHiddenClass)) {
					self.showSlide();
				} else {
					self.hideSlide();
				}
			};
			self.opener.on(self.options.event, this.eventHandler);

			// hover mode handler
			if (self.options.event === 'hover') {
				self.opener.on('mouseenter', function() {
					if (!self.holder.hasClass(self.options.activeClass)) {
						self.showSlide();
					}
				});
				self.holder.on('mouseleave', function() {
					self.hideSlide();
				});
			}

			// outside click handler
			self.outsideClickHandler = function(e) {
				if (self.options.hideOnClickOutside) {
					var target = $(e.target);
					if (!target.is(self.holder) && !target.closest(self.holder).length) {
						self.hideSlide();
					}
				}
			};

			// set initial styles
			if (this.holder.hasClass(this.options.activeClass)) {
				$(document).on('click touchstart', self.outsideClickHandler);
			} else {
				this.slider.addClass(slideHiddenClass);
			}
		},
		showSlide: function() {
			var self = this;
			if (self.options.addClassBeforeAnimation) {
				self.holder.addClass(self.options.activeClass);
			}
			self.slider.removeClass(slideHiddenClass);
			$(document).on('click touchstart', self.outsideClickHandler);

			self.makeCallback('animStart', true);
			toggleEffects[self.options.effect].show({
				box: self.slider,
				speed: self.options.animSpeed,
				complete: function() {
					if (!self.options.addClassBeforeAnimation) {
						self.holder.addClass(self.options.activeClass);
					}
					self.makeCallback('animEnd', true);
				}
			});
		},
		hideSlide: function() {
			var self = this;
			if (self.options.addClassBeforeAnimation) {
				self.holder.removeClass(self.options.activeClass);
			}
			$(document).off('click touchstart', self.outsideClickHandler);

			self.makeCallback('animStart', false);
			toggleEffects[self.options.effect].hide({
				box: self.slider,
				speed: self.options.animSpeed,
				complete: function() {
					if (!self.options.addClassBeforeAnimation) {
						self.holder.removeClass(self.options.activeClass);
					}
					self.slider.addClass(slideHiddenClass);
					self.makeCallback('animEnd', false);
				}
			});
		},
		destroy: function() {
			this.slider.removeClass(slideHiddenClass).css({
				display: ''
			});
			this.opener.off(this.options.event, this.eventHandler);
			this.holder.removeClass(this.options.activeClass).removeData('OpenClose');
			$(document).off('click touchstart', this.outsideClickHandler);
		},
		makeCallback: function(name) {
			if (typeof this.options[name] === 'function') {
				var args = Array.prototype.slice.call(arguments);
				args.shift();
				this.options[name].apply(this, args);
			}
		}
	};

	// add stylesheet for slide on DOMReady
	var slideHiddenClass = 'js-slide-hidden';
	(function() {
		var tabStyleSheet = $('<style type="text/css">')[0];
		var tabStyleRule = '.' + slideHiddenClass;
		tabStyleRule += '{position:absolute !important;left:-9999px !important;top:-9999px !important;display:block !important}';
		if (tabStyleSheet.styleSheet) {
			tabStyleSheet.styleSheet.cssText = tabStyleRule;
		} else {
			tabStyleSheet.appendChild(document.createTextNode(tabStyleRule));
		}
		$('head').append(tabStyleSheet);
	}());

	// animation effects
	var toggleEffects = {
		slide: {
			show: function(o) {
				o.box.stop(true).hide().slideDown(o.speed, o.complete);
			},
			hide: function(o) {
				o.box.stop(true).slideUp(o.speed, o.complete);
			}
		},
		fade: {
			show: function(o) {
				o.box.stop(true).hide().fadeIn(o.speed, o.complete);
			},
			hide: function(o) {
				o.box.stop(true).fadeOut(o.speed, o.complete);
			}
		},
		none: {
			show: function(o) {
				o.box.hide().show(0, o.complete);
			},
			hide: function(o) {
				o.box.hide(0, o.complete);
			}
		}
	};

	// jQuery plugin interface
	$.fn.openClose = function(opt) {
		var args = Array.prototype.slice.call(arguments);
		var method = args[0];

		return this.each(function() {
			var $holder = jQuery(this);
			var instance = $holder.data('OpenClose');

			if (typeof opt === 'object' || typeof opt === 'undefined') {
				$holder.data('OpenClose', new OpenClose($.extend({
					holder: this
				}, opt)));
			} else if (typeof method === 'string' && instance) {
				if (typeof instance[method] === 'function') {
					args.shift();
					instance[method].apply(instance, args);
				}
			}
		});
	};
}(jQuery));